<template>
  <v-main class="">
    <app-layout :image="url_imagen" @errorImagen="imagenError">
      <template #title>
        <v-card-text
          class="d-flex justify-center white--text text-h2 font-weight-black"
        >
          <h1
            class="text-body-2 text-sm-subtitle-2 text-md-h2 font-weight-bold white--text text-uppercase"
          >
            {{ titulo }}
          </h1>
        </v-card-text>
      </template>
      <template #body-content>
        <v-container>
          <v-row>
            <v-col>
              <v-btn
                :to="{ name: 'recreacion-index' }"
                rounded
                color="#3aa5f0"
                dark
                class="px-8 text-capitalize"
              >
                Regresar
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-container
          class="elevation-10 my-10 rounded-xxl"
          style="background-color: #f4f7fd"
        >
          <v-row class="pa-10">
            <v-col cols="12" sm="6" md="4" v-for="data in info" :key="data.id">
              <v-icon x-large class="color-label">{{ data.icon }}</v-icon
              ><span class="color-label text-h5 font-weight-bold">{{
                data.label
              }}</span>
              <span></span>
              <p
                style="color: #71788b"
                class="text-subtitle-1 font-weight-medium ml-11"
              >
                {{ data.data }}
              </p>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </app-layout>
  </v-main>
</template>
<script>
import AppHeaderBanner from "../../components/AppHeaderBanner.vue";
import moment from "moment";
import AppLayout from "../../components/AppLayoutComponent.vue";
export default {
  name: "DetalleEvento",
  components: {
    AppHeaderBanner,
    AppLayout,
  },
  props: {},
  data: () => ({
    tipo: "concierto",
    titulo: null,
    info: [],
    url_imagen: "",
  }),
  methods: {
    async loadInformation() {
      const id = this.$route.params.id;
      const response = await this.services.Recreacion.getEventoInfo(id);
      const data_unformat = response.data;
      if (response?.status === 200) {
        this.titulo = data_unformat.nombre;
        this.info = [
          {
            id: 1,
            icon: "mdi-calendar-blank-outline",
            label: "Fecha",
            data: moment(data_unformat.fecha).format("DD/MM/YYYY"),
          },
          {
            id: 2,
            icon: "mdi-map-marker",
            label: "Lugar del Evento",
            data: data_unformat.lugar,
          },
          {
            id: 3,
            icon: "mdi-map-marker",
            label: "Dirección",
            data: data_unformat.direccion,
          },
          {
            id: 4,
            icon: "mdi-map-marker-radius-outline",
            label: "Departamento",
            data: data_unformat?.CtlMunicipio?.departamento?.nombre,
          },
          {
            id: 5,
            icon: "mdi-map-marker-radius-outline",
            label: "Municipio",
            data: data_unformat?.CtlMunicipio?.nombre,
          },
        ];
        this.url_imagen = this.getImagenURL(
          data_unformat?.path_file,
          data_unformat?.uuid_descripcion
        );
      } else {
        this.info = [];
        this.titulo = "";
        this.url_imagen = "";
      }

    },
    imagenError() {
      this.url_imagen = 'banner-evento.svg'
    }
  },
  async created() {
    await this.loadInformation();
  },
};
</script>
<style scoped>
/* Imagen del inicio */
.color-label {
  color: #3aa5f0;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Textfiel buscar */

.rounded-text-field {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  padding: 8px 16px;
  border-color: #3daaf5;
  border-width: 1px;

  border-style: solid;
}

input {
  outline: none;
  flex: 1;
  padding: 0 8px;
  font-size: 16px;
  border-radius: 30px;
}

i {
  margin-left: 8px;
  font-size: 18px;
  color: #3daaf5;
}

.text-gradient {
  background: linear-gradient(90deg, #1d7ed6 0%, #73dc96 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

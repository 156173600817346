<template>

      <v-card color="white d-flex align-center " style="border-radius:10px; height: 165px; width: 400px; ">
          <v-container >
              <v-row no-gutters>
              <v-col cols="4" >
                  <!-- <v-img :src="url_imagen" lazy-src="loading.jpg" width="80" height="80"></v-img> -->
                  <img :src="url_imagen" width="100em" height="100em" />
              </v-col>
              <v-col cols="8"  >
                  <v-row  no-gutters >
                    <v-col cols="12" style="padding: 0; height: 50%;">
                      <h6 style="font-size:1.10em;color:rgb(58, 164, 241)" > {{ nombre }}</h6>
                    </v-col>
                    <v-col cols="12" style="padding: 0; height: 50%;">
                      <h4 style="font-size:1.50em;color:rgb(58, 164, 241)"> {{ numero }}</h4>
                    </v-col>
                  </v-row>
              </v-col>  
              </v-row>
          </v-container>
      </v-card>

  </template>
    
    <script>
    export default {
      name: "ComponentCardContacto",
      props: {
        nombre: {
          type: String,
          default: 'Sin registro',
        },
        numero: {
          type: String,
          default: 'Sin registro',
        },
        url_imagen: {
          type: String,
          default: 'Sin registro',
        }
      },
      data: () => ({}),
      methods:{
        ImagenError(){
          this.print_url = require( `../../../assets/image-not-found.png`)
        }
      },
      computed:{
        print_nombre(){
          return this.nombre;
        },
        print_numero(){
          return this.numero;
        },
        print_url:{
          get(){
            return this.url_imagen;
          },
          set(newValue){
            this.url_imagen = newValue;
          }
        },
      }
    }
    </script>
    
    
    
    <style>
    @import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");
    
    body {
      font-family: "Source Sans Pro", sans-serif;
      font-size: 16px;
    }
    
    .card-form {
      max-width: 450px;
      margin: auto;
      width: 100%;
    }
    
    @media screen and (max-width: 576px) {
      /*.card-form {
        margin: 0 auto;
      }*/
    }
    
    .card-form__inner {
     /* background: #b81313;*/
     /*background-image:url('https://cdn.vuetifyjs.com/images/cards/sunshine.jpg'); */
      background-position: center center;
      background-size: cover;
      background-repeat:no-repeat;
    
      -webkit-box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
              box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
      border-radius: 10px;
      padding: 35px;
      padding-top: 290px;
    }
    
    @media screen and (max-width: 480px) {
      .card-form__inner {
        padding: 25px;
        padding-top: 165px;
      }
    }
    
    @media screen and (max-width: 360px) {
      .card-form__inner {
        padding: 15px;
        padding-top: 165px;
      }
    }
    
    .card-form__button {
      width: 75%;
      margin-left:10%;
      height: 55px;
      /*background: #2364d2;*/
      background: linear-gradient(to right,#1b8c50,#1171a4);
      text-align: center;
      border: none;
      border-radius: 5px;
      font-size: 22px;
      font-weight: 500;
      font-family: "Source Sans Pro", sans-serif;
      -webkit-box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
              box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
      color: #fff;
      margin-top: 20px;
      cursor: pointer;
    }
    
    @media screen and (max-width: 480px) {
      .card-form__button {
        margin-top: 10px;
      }
    }
    
    .card-item {
      max-width: 150px;
      height: 150px;
      margin-left: 10%;
      margin-right: auto;
      position: relative;
      z-index: 2;
      width: 100%;
     
    }
    
    @media screen and (max-width: 480px) {
    
    .card-item__cover2 {
        /*background-color: red;*/
        position: absolute;
        width:20% !important;
        border-right: 15px solid  #e0cb0c !important;
        border-top: 15px solid transparent  !important;
        border-left: 15px solid transparent  !important;
        border-bottom: 15px solid #e0cb0c !important;
        /*border-radius: 15px;*/
    }
    
    .card-item__cover {
      height: 100%;
      background-color: #ffe81e;
      position: absolute;
      height: 100%;
      left: 28px !important;
      top: 0;
      width: 80%;
      /*border-radius: 15px;*/
      overflow: hidden;
    }
    
    }
    .card-item__cover {
      height: 100%;
      background-color: #ffe81e;
      position: absolute;
      height: 100%;
      left: 20px;
      top: 0;
      width: 90%;
      /*border-radius: 15px;*/
      overflow: hidden;
    }
    
    
    .card-item__cover2 {
      /*background-color: red;*/
      position: absolute;
      margin-right:10px;
      width: 8%;
      height: 13%;
    
      border-right: 10px solid  #e0cb0c;
      border-top: 10px solid transparent;
      border-left: 10px solid transparent;
      border-bottom: 10px solid #e0cb0c;
      /*border-radius: 15px;*/
    }
    
    .card-item__type {
      height: 45px;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      max-width: 100px;
      margin-left: auto;
      width: 100%;
    }
    
    @media screen and (max-width: 480px) {
      .card-item__type {
        height: 40px;
        max-width: 90px;
      }
    }
    
    @media screen and (max-width: 360px) {
      .card-item__type {
        height: 30px;
      }
    }
    
    .card-item__typeImg {
      max-width: 100%;
      -o-object-fit: contain;
         object-fit: contain;
      max-height: 100%;
      -o-object-position: top right;
         object-position: top right;
    }
    
    .card-item__info {
      color: #fff;
      width: 100%;
      max-width: calc(100% - 85px);
      padding: 10px 15px;
      font-weight: 500;
      display: block;
      cursor: pointer;
    }
    
    @media screen and (max-width: 480px) {
      .card-item__info {
        padding: 10px;
      }
    }
    
    .card-item__holder {
      opacity: 0.7;
      font-size: 13px;
      margin-bottom: 6px;
    }
    
    @media screen and (max-width: 480px) {
      .card-item__holder {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
    
    .card-item__wrapper {
      font-family: "Source Code Pro", monospace;
      font-size: 25px;
      padding: 13px 37px;
      position: relative;
      z-index: 4;
      height: 100%;
     /* text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);*/
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
    }
    
    @media screen and (max-width: 480px) {
      /*.card-item__wrapper {
        padding: 20px 10px;
      }*/
    }
    
    .card-item__content {
      color: #080808;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
          -ms-flex-align: start;
              align-items: flex-start;
    }
    
    .card-list {
      margin-bottom: -130px;
    }
    
    @media screen and (max-width: 480px) {
      .card-list {
        margin-bottom: -120px;
      }
    }
    
    .card-input {
      margin-bottom: 20px;
      color:white;
    }
    
    </style>
    
    
<template>
 
    <infinite-slider>
          <template v-slot:default="{preIndex}">
              <ComponentCardContacto
                style="margin-left: 5em;margin-left: 5em;"
                v-for="evento in array"
                :key="`${preIndex}${evento.id}`"

                :nombre="evento.nombre"
                :url_imagen="evento.url_imagen"
                :numero="evento.telefono"
              >
              </ComponentCardContacto>
          </template>
        </infinite-slider>

</template>
<script>
import InfiniteSlider from "./InfiniteSlider.vue";
import ComponentCardContacto from "./ComponentCardContacto.vue";

export default {
  name: "Contactos",
  components: {
    InfiniteSlider,
    ComponentCardContacto,
  },
  props: {
    array: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    show: false,
  }),
  methods: {},
};
</script>
<style scoped>
.container-contactos {
  width: 100%;

  background: #f4f6fc;
}
.set {
  background: #f4f6fc;
  width: 100%;
  height: 100px;

  border-radius: 60% / 100%;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

<template>
    <div class="text-center">
      <v-dialog
        v-model="dialog"
        :width="width ? '700' : '500'"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn
              :x-large="width ? true : false"
              :width="width ? '240' : '160'"
              rounded
              :color="!hover ? 'grey lighten-1': '#30A4F1'"
              dark
              v-bind="attrs"
              v-on="on"
            >
              <slot name="titulo"></slot>
            </v-btn>
        </v-hover>
        </template>
  
        <v-card class="rounded-xl">
          <slot name="ifonModal"></slot>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  export default {
    data () {
      return {
        dialog: false,
      }
    },
    computed: {
      width() {
        return this.$vuetify.breakpoint.width > 1903
      }
    }
  }
</script>
<template>
    <app-layout image="index-recreacion.svg" :is-menu-btn="true" >
      <template #title>
        <div class="d-flex justify-center align-center flex-column">
          <p
              class="text-h6 text-sm-h4 text-md-h3 text-lg-h1 text-center white--text font-weight-black"
          >
            Descubre la belleza de
          </p>
          <p
              class="text-h6 text-sm-h4 text-md-h3 text-lg-h1 text-center white--text font-weight-black"
          >
            El Salvador
          </p>
        </div>
      </template>
      <template #body-content>
        <v-container fluid class="pa-0" style="overflow-x: hidden;">
        <div class="mb-16 mt-10">
          <v-row class="row justify-end mx-10">
            <v-col cols="12" sm="6" md="4" justify-end>
              <div class="rounded-text-field">
                <input type="text" @keyup="empiezaFiltro()" v-model="filtro" />
                <i class="mdi mdi-magnify"></i>
              </div>
            </v-col>
            <v-col cols="12" justify-center>
              <p
                class="text-md-h5 text-h5 font-weight-bold"
                style="color: #3ba5f1"
              >
                CATEGORÍAS
              </p>
            </v-col>
          </v-row>
        </div>
        <Slider
          :carrouselisto="isArrayCategoriaReady"
          :total-items="array_categoria.length"
        >
          <template #componentCard>
            <div v-for="(evento, index) in array_categoria" :key="evento.id">
              <div
                class="slide"
                :index="`${index + 1}`"
                style="margin-left: 60px; margin-right: 20px"
              >
                <ComponentCardCategoria
                  :titulo="evento.nombre"
                  :descripcion="evento.descripcion"
                  :url_imagen="evento.url_imagen"
                  :id="evento.id"
                  :object-route-ver-mas="{
                    name: 'detalle-categoria',
                    params: { id: evento.id },
                  }"
                >
                </ComponentCardCategoria>
              </div>
            </div>
          </template>
        </Slider>
        <div class="mb-16 mt-10">
          <v-row class="row justify-end mx-10">
            <v-col cols="12" justify-center>
              <p
                class="text-md-h5 text-h5 font-weight-bold"
                style="color: #3ba5f1"
              >
                DISFRUTA LOS MEJORES EVENTOS
              </p>
            </v-col>
          </v-row>
        </div>
        <Slider
          :carrouselisto="isArrayEventosReady"
          :total-items="array_eventos.length"
        >
          <template #componentCard>
            <div v-for="(evento, index) in array_eventos" :key="evento.id">
              <div
                class="slide"
                :index="`${index + 1}`"
                style="margin-left: 40px; margin-right: 40px"
              >
                <ComponentCardEvento
                  :titulo="evento.nombre"
                  :descripcion="evento.lugar"
                  :url_imagen="evento.url_imagen"
                  :fecha="evento.fecha_formated"
                  :id="evento.id"
                >
                </ComponentCardEvento>
              </div>
            </div>
          </template>
        </Slider>
        <v-row>
          <v-col class="text-center mt-10 ml-16 mr-16">
            <span
              style="color: #3aa4f1"
              class="text-md-h5 text-subtitle-1 font-weight-bold"
            >
              Consulta los contactos de emergencia disponibles
            </span>
          </v-col>
        </v-row>
        <div style="margin-top: 1lh" v-if="isArrayEmergenciasReady">
          <contactos :array="array_emergencia"></contactos>
        </div>
      </v-container>
    </template>
  </app-layout>
</template>
<script>
import AppHeaderBanner from "../../components/AppHeaderBanner.vue";
import ComponentCardEvento from "./components/ComponentCardEvento.vue";
import ComponentCardCategoria from "./components/ComponentCardCategoria.vue";
import AppLayout from "../../components/AppLayoutComponent.vue";
import Slider from "./components/slider.vue";
import moment from "moment";
moment.locale("es");
import Contactos from "./components/ContactosComponent.vue";
import { Carousel, Slide } from "vue-carousel";
export default {
  name: "RecreacionIndex",
  components: {
    Contactos,
    Carousel,
    Slide,
    Slider,
    ComponentCardEvento,
    ComponentCardCategoria,
    AppHeaderBanner,
    AppLayout,
  },
  props: {},
  data: () => ({
    isArrayEventosReady: false,
    isArrayEmergenciasReady: false,
    isArrayCategoriaReady: false,
    show: false,
    pagina: 0,
    array_eventos: [],
    array_emergencia: [],
    array_categoria: [],
    filtro: "",
    delayTimer: null,
  }),
  methods: {
    async empiezaFiltro() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }
      // Creamos un nuevo temporizador que llamará a nuestro método después de 500ms (medio segundo).
      this.delayTimer = setTimeout(() => {
        this.getCategoria();
        this.getEventos();
      }, 500);
    },
    async getEventos() {
      this.isArrayEventosReady = false;
      const params = {
        busqueda: !!this.filtro ? this.filtro : "",
        pagination: false,
        web: true,
      };
      const response = await this.services.Recreacion.getEventos(params);

      if (response?.status === 200) {
        const data_unformat = response.data;
        const data_format = data_unformat.map((x) => {
          const a = { ...x };
          const fecha_formated = moment(x.fecha, "DD/MM/YYYY").format("DD MMM");
          const palabras = fecha_formated.split(" ");
          a.fecha_formated = [
            palabras[0],
            `${palabras[1][0].toUpperCase()}${palabras[1].substring(1)}`,
          ];
          a.url_imagen = this.getImagenURL(x?.path_file, x?.uuid_portada);
          return a;
        });
        this.array_eventos = [];
        this.array_eventos = data_format;
        data_format.length !== 0
          ? (this.isArrayEventosReady = true)
          : (this.isArrayEventosReady = false);
      } else {
        this.array_eventos = [];
      }
    },
    async getEmergencia() {
      this.isArrayEmergenciasReady = false;
      const response = await this.services.Recreacion.getEmergencia({
        pagination: false,
        web: true,
      });

      if (response?.status === 200) {
        const data_unformat = response.data;
        const data_format = data_unformat.map((x) => {
          const a = { ...x };
          a.telefono = x.contactos[0]?.contacto;

          a.url_imagen = this.getImagenURL(x?.path_file, x?.uuid_imagen[0]);

          return a;
        });
        this.array_emergencia = data_format;
        this.isArrayEmergenciasReady = true;
      } else {
        this.array_emergencia = [];
      }
    },
    async getCategoria() {
      const params = {
        busqueda: !!this.filtro ? this.filtro : "",
        pagination: false,
        web: true,
      };
      this.isArrayCategoriaReady = false;
      const response = await this.services.Recreacion.getCategoria({
        ...params,
      });

      if (response?.status === 200) {
        const data_unformat = response.data;
        const data_format = data_unformat.map((x) => {
          const a = { ...x };
          a.url_imagen = this.getImagenURL(x?.path_file, x?.uuid_portada);
          return a;
        });
        this.array_categoria = [];
        this.array_categoria = data_format;
        data_format.length !== 0
          ? (this.isArrayCategoriaReady = true)
          : (this.isArrayCategoriaReady = false);
      } else {
        this.array_categoria = [];
      }
    },
  },

  async created() {
    await this.getEventos();
    await this.getEmergencia();
    await this.getCategoria();
  },
};
</script>
<style scoped>
/* Imagen del inicio */
.container-banner {
  position: relative;
  text-align: center;
  color: white;
}

/* Top left text */
.top-left {
  position: absolute;
  top: 8px;
  left: 16px;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Textfiel buscar */

.rounded-text-field {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  padding: 8px 16px;
  border-color: #3daaf5;
  border-width: 1px;

  border-style: solid;
}

input {
  outline: none;
  flex: 1;
  padding: 0 8px;
  font-size: 16px;
  border-radius: 30px;
}

i {
  margin-left: 8px;
  font-size: 18px;
  color: #3daaf5;
}

.text-gradient {
  background: linear-gradient(90deg, #1d7ed6 0%, #73dc96 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

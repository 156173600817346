<template>
  <div class="mt-10 mb-10">
    <VueSlickCarousel v-if="carrouselisto" v-bind="settings">
      <slot name="componentCard"></slot>
    </VueSlickCarousel>
    <!-- <ssr-carousel
      v-if="carrouselisto"
      ref="xd"
      gutter="0%"
      :slides-per-page="dynimicSLidesPerPage"
      v-model="pagina"
      paginate-by-slide
    >
      <slot name="componentCard"></slot>
    </ssr-carousel> -->
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  components: {
    VueSlickCarousel,
  },
  props: {
    carrouselisto: Boolean,
    totalItems: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    pagina: 0,
  }),
  computed: {
    settings() {
      const getSlidesToShow = () => {
        switch (this.$vuetify.breakpoint.name) {
          case "xs":
            return 1;
          case "sm":
            return this.totalItems < 2 ? this.totalItems : 2;
          case "md":
            return this.totalItems < 3 ? this.totalItems : 3;
          case "lg":
            return this.totalItems < 3 ? this.totalItems : 3;
          case "xl":
            return this.totalItems < 4 ? this.totalItems : 4;
          case "2xl":
            return this.totalItems < 5 ? this.totalItems : 5;
        }
      };
      return {
        // centerMode: this.$vuetify.breakpoint.name === "xs",
        // centerPadding: "10px",

        infinite: false,
        speed: 500,
        initialSlide: 0,

        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        swipeToSlide: false,
        responsive: [
          {
            breakpoint: 2560,
            settings: {
              slidesToShow: this.totalItems < 5 ? this.totalItems : 5,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: this.totalItems < 4 ? this.totalItems : 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: this.totalItems < 3 ? this.totalItems : 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: this.totalItems < 3 ? this.totalItems : 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: this.totalItems < 2 ? this.totalItems : 2,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    },
    dynimicSLidesPerPage() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 1;
        case "sm":
          return this.totalItems < 2 ? this.totalItems : 2;
        case "md":
          return this.totalItems < 3 ? this.totalItems : 3;
        case "lg":
          return this.totalItems < 3 ? this.totalItems : 3;
        case "xl":
          return this.totalItems < 4 ? this.totalItems : 4;
        case "2xl":
          return this.totalItems < 5 ? this.totalItems : 5;
      }
    },
  },
  methods: {},
  watch: {},
  created() {},
};
</script>

<template>
  <div class="card-form" style="position: relative; margin: 0px auto;">
    <div
      class="card-list"
      style="
        position: absolute; /*! top: 0; */ /*! left: 0px; */ /*! display: none; */
      "
    >
      <div class="card-item">
        <div class="card-item__side-front">
          <div class="card-item__cover"></div>
          <div class="card-item__wrapper">
            <div class="card-item__content d-block pt-0">
              <h5
                class="text-h4 font-weight-black"
                style="color: rgb(67, 182, 238)"
              >
                {{ print_dia }}
              </h5>
              <h5
                class="text-h5 font-weight-bold"
                style="color: rgb(67, 182, 238)"
              >
                {{ print_mes }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="card-form__inner elevation-0"
      v-bind:style="{
        backgroundImage: 'url(' + url_imagen2 + ')',
        height: '330px',
        position: 'relative',

      }"
    >
      <img
        :src="url_imagen2"
        style="display:none"
        @error="ImagenError"
      />
      <div
        style="
          position: absolute;
          top: 115px;
          padding: 0px 15px;
          display: flex;
          flex-direction: column;
          height: 147px;
          justify-content: flex-end;
        "
      >
        <h1
          style="
            font-size: 1.5em !important; /*! white-space: normal; */ /*! width: 224px; */
            overflow-wrap: break-word;
            color: white; /*! position: absolute; */ /*! top: 115px; */ /*! width: 230px; */ /*! left: 22px; */ /*! line-height: 1.7rem; */ /*! text-align: justify; */ /*! display: inline; */
          "
        >
          {{ print_titulo }}
        </h1>
        <h3 style="font-size: 1em !important; color: white; width: 100%">
          {{ print_descripcion }}
        </h3>
      </div>
    </div>
    <div
      style="
        position: absolute;
        bottom: 15px;
        width: 100%;
        display: flex;
        justify-content: center;
      "
    >
      <v-btn
        :to="{ name: 'detalle-evento', params: { id: print_id } }"
        aria-current="page"
        class="v-btn--active v-btn v-btn--is-elevated v-btn--has-bg v-btn--rounded v-btn--router theme--dark v-size--default"
        style="
          text-transform: none;
          background-color: rgb(67, 182, 238);
          border-color: rgb(
            67,
            182,
            238
          ); /*! margin-left: 20%; */ /*! margin-top: 5px; */ /*! position: absolute; */ /*! bottom: 0px; */
        "
      >
        <span class="v-btn__content">
          Ver más
          <i
            aria-hidden="true"
            class="v-icon notranslate mdi mdi-arrow-right-drop-circle material-icons theme--dark"
          >
          </i
        ></span>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentCard",
  props: {
    id: {
      type: Number,
      default: "1",
    },
    titulo: {
      type: String,
      default: "Sin registro",
    },
    descripcion: {
      type: String,
      default: "Sin registro",
    },
    url_imagen: {
      type: String,
      default: "Sin registro",
    },
    fecha: {
      type: Array,
      default: ["01", "Ene."],
    },
    id: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    url_imagen2: ''
  }),
  methods: {
    ImagenError() {
      this.url_imagen2 = require(`../../../assets/image-not-found.png`);
    },
  },
  created(){
    this.url_imagen2 = this.url_imagen
  },
  computed: {
    print_id() {
      return this.id;
    },
    print_titulo() {
      return this.titulo;
    },
    print_descripcion() {
      return this.descripcion;
    },
    print_dia() {
      return this.fecha[0];
    },
    print_mes() {
      return this.fecha[1];
    },
    print_id() {
      return this.id;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

body {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 16px;
}

.card-form {

  width: 90% !important;
  max-height:  401px!important;
  max-width: 420px!important;
}

@media screen and (max-width: 576px) {
  /*.card-form {
        margin: 0 auto;
      }*/
}

.card-form__inner {
  /* background: #b81313;*/
  /*background-image:url('https://cdn.vuetifyjs.com/images/cards/sunshine.jpg'); */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  -webkit-box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  max-height: 401px;
  padding: 10px;
  padding-top: 150px !important;
}

@media screen and (max-width: 480px) {
  .card-form__inner {
    padding: 25px;
    padding-top: 165px;
  }
}

@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
    padding-top: 165px;
  }
}

.card-form__button {
  width: 75%;
  margin-left: 10%;
  height: 40px !important;
  /*background: #2364d2;*/
  background: linear-gradient(to right, #1b8c50, #1171a4);
  text-align: center;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 10px !important;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .card-form__button {
    margin-top: 10px;
  }
}

.card-item {
  max-width: 100px !important;
  height: 100px !important;
  margin-left: 10%;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .card-item__cover2 {
    /*background-color: red;*/
    position: absolute;
    width: 20% !important;
    border-right: 15px solid #e0cb0c !important;
    border-top: 15px solid transparent !important;
    border-left: 15px solid transparent !important;
    border-bottom: 15px solid #e0cb0c !important;
    /*border-radius: 15px;*/
  }

  .card-item__cover {
    height: 100%;
    background-color: #ffe81e;
    position: absolute;
    height: 100%;
    left: 28px !important;
    top: 0;
    width: 80%;
    /*border-radius: 15px;*/
    overflow: hidden;
  }
}
.card-item__cover {
  height: 100%;
  background-color: #ffffff !important;
  border-radius: 0px 0px 60px 60px;
  position: absolute;
  height: 100%;
  left: 10px;
  top: 0;
  width: 90%;
  /*border-radius: 15px;*/
  overflow: hidden;
}

.card-item__cover2 {
  /*background-color: red;*/
  position: absolute;
  margin-right: 10px;
  width: 8%;
  height: 13%;

  border-right: 10px solid #e0cb0c;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #e0cb0c;
  /*border-radius: 15px;*/
}

.card-item__type {
  height: 45px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .card-item__type {
    height: 40px;
    max-width: 90px;
  }
}

@media screen and (max-width: 360px) {
  .card-item__type {
    height: 30px;
  }
}

.card-item__typeImg {
  max-width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  max-height: 100%;
  -o-object-position: top right;
  object-position: top right;
}

.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 10px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}

@media screen and (max-width: 480px) {
  .card-item__info {
    padding: 10px;
  }
}

.card-item__holder {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}

@media screen and (max-width: 480px) {
  .card-item__holder {
    font-size: 12px;
    margin-bottom: 5px;
  }
}

.card-item__wrapper {
  font-family: "Source Code Pro", monospace;
  font-size: 15px !important;
  padding: 15px 45px !important;
  position: relative;
  z-index: 4;
  height: 100%;
  /* text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);*/
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (max-width: 480px) {
  /*.card-item__wrapper {
        padding: 20px 10px;
      }*/
}

.card-item__content {
  color: #080808;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.card-list {
  margin-bottom: -100px !important;
}

@media screen and (max-width: 480px) {
  .card-list {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 10px !important;
  color: white;
}
</style>

<template>
    <v-card
      class="mx-auto"
      :max-width="this.$vuetify.breakpoint.width > 320 ? '270': '70vw'"
      rounded="xxl"
    >
      <v-img
        :src="urlImg"
        height="300px"
      ></v-img>
    </v-card>
  </template>
  <script>
  
  export default {
    props: {
        urlImg: String
    },
    data: () =>({
        
    }),
  }
  </script>
  
<template>
  <v-main class="">
    <app-layout :image="url_imagen" @errorImagen="imagenError()"  :is-menu-btn="true">
      <template #title>
        <v-card-title class="text-h4 white--text mb-4">
          <h1
            class="text-body-2 text-sm-subtitle-2 text-md-h2 font-weight-bold white--text text-uppercase"
          >
            {{ titulo }}
          </h1>
        </v-card-title>
      </template>
      <template #body-content>
        <v-container class="mt-10">
          <v-row>
            <v-col cols="12" sm="8">
              <v-btn
                @click="$router.go(-1)"
                rounded
                color="#3aa5f0"
                dark
                class="px-8 text-capitalize"
              >
                Regresar
              </v-btn>
            </v-col>
            <v-col cols="12" sm="4" justify-end>
              <div class="rounded-text-field">
                <input type="text" v-model="filtro" @keyup="empiezaFiltro()" />
                <i class="mdi mdi-magnify"></i>
              </div>
            </v-col>
          </v-row>
        </v-container>
        <v-container class="my-10" v-if="showMessagePlace">
          <v-row class="pa-10 align-center justify-center">
            <h2>No se encontraron registros</h2>
          </v-row>
        </v-container>
        <v-container class="my-10">
          <v-row class="pa-10">
            <v-col cols="12" sm="6" md="4" v-for="data in info" :key="data.id">
              <ComponentCardCategoria
                :titulo="data.nombre"
                :descripcion="''"
                :url_imagen="data.url_imagen"
                :object-route-ver-mas="{
                  name: 'detalle-lugar',
                  params: { id: data.id },
                }"
              >
              </ComponentCardCategoria>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </app-layout>
  </v-main>
</template>
<script>
import AppHeaderBanner from "../../components/AppHeaderBanner.vue";
import ComponentCardCategoria from "./components/ComponentCardCategoria.vue";
import AppLayout from "../../components/AppLayoutComponent.vue";
export default {
  name: "DetalleCategoria",
  components: {
    AppHeaderBanner,
    ComponentCardCategoria,
    AppLayout
  },
  props: {},
  data: () => ({
    titulo: "",
    url_imagen: "",
    delayTimer: null,
    filtro: "",
    info: [],
    showMessagePlace: true
  }),
  methods: {
    imagenError(){
      this.url_imagen = "image-not-found.jpg"
    },
    async loadInformation() {
      const id = this.$route.params.id;
      const params = {
        pagination: false,
        nombre: !!this.filtro ? this.filtro : "",
        web: true,
      };

      const response = await this.services.Recreacion.getLugares(id, params);
      const data_unformat = response.data;
      if (response?.status === 200) {
        if (Object.entries(data_unformat).length !== 0) {
          this.titulo = data_unformat.nombre;
          this.url_imagen = this.getImagenURL(
            data_unformat?.path_file,
            data_unformat?.uuid_descripcion
          );

          const lugares = data_unformat?.lugares.map((x) => {
            const a = { ...x };
            a.url_imagen = this.getImagenURL(x?.path_file, x?.uuid_portada);
            return a;
          });
          this.info = [];
          this.info = lugares;
        } else {
          this.info = [];
        }
        this.showMessagePlace = data_unformat?.lugares?.length <= 0;
      } else {
        this.info = [];
        this.titulo = "";
        this.url_imagen = "";
      }
    },
    async empiezaFiltro() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }

      // Creamos un nuevo temporizador que llamará a nuestro método después de 500ms (medio segundo).
      this.delayTimer = setTimeout(() => {
        this.loadInformation();
      }, 500);
    },
  },
  async created() {
    await this.loadInformation();
  },
};
</script>
<style scoped>
/* Imagen del inicio */
.color-label {
  color: #3aa5f0;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Textfiel buscar */

.rounded-text-field {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
  padding: 8px 16px;
  border-color: #3daaf5;
  border-width: 1px;

  border-style: solid;
}

input {
  outline: none;
  flex: 1;
  padding: 0 8px;
  font-size: 16px;
  border-radius: 30px;
}

i {
  margin-left: 8px;
  font-size: 18px;
  color: #3daaf5;
}

.text-gradient {
  background: linear-gradient(90deg, #1d7ed6 0%, #73dc96 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<template>
  <div>
    <div class="Marquee">
      <div class="Marquee-content">
        <template v-for="(x,index) in 2"  >
            <slot :preIndex="x">
              <div :key="`${x}${index}`">
              </div>
            </slot>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "InfiniteCarousel",
  props: {},
  data: () => ({

  }),
  methods: {},
};
</script>
<style scoped>
.Marquee {
  width: 100%;
  box-sizing: border-box;
  padding: 1em;
  color: #fff;
  font-weight: 200;
  display: flex;
  align-items: center;
  overflow: hidden;

}
.Marquee-content {
  display: flex;
  animation: marquee 40s linear infinite ;

 
}
@-moz-keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}
@-webkit-keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}
@-o-keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}
@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(-50%);
  }
}

</style>

<template>
  <section style="overflow-x: hidden;">
    <app-layout-component :image="img_portada">
      <template #title>
        <div class="d-flex justifu-center flex-column">
          <app-title :heading="textHeader" text="VISITA" weight="regular" flex="justify-center" textColor="white" />
          <app-title :heading="textHeader" :text="detalleLuagar.nombre" weight="black" textColor="white" Transform="uppercase"
            flex="justify-center" />
        </div>
      </template>
      <template #body-content>
       <v-container>
         <v-row class="group-button" >
          <v-col cols="12" sm="4">
            <modal>
              <template #titulo>
                <strong :class="`text-${typeClass}`" class="text-capitalize d-flex"><v-icon
                    class="mr-2" :class="`text-${typeClass}`">mdi-calendar-blank</v-icon>Horarios</strong>
              </template>
              <template #ifonModal>
                <v-card-title class="text-h5 d-flex justify-center">
                  <v-icon color="#30A4F1" large>mdi-calendar-blank</v-icon><app-title heading="h4" text="Horarios"
                    weight="black" textColor="#30A4F1" />
                </v-card-title>

                <v-card-text>
                  <div v-for="({ dia, ...horario }, id) in detalleLuagar.horarios" :key="id"
                    class="d-flex justify-center text-h5 grey--text mt-5">
                    <v-row>
                      <v-col cols="12" class="text-h5 d-flex" v-if="horario.abierto">
                        <label for="" :class="`text-${typeClass}`" class="d-flex justify-start text--horario mr-10"><strong>{{
                          dia.nombre }}</strong></label>
                        <label for="" :class="`text-${typeClass}`">{{ `${horario.hora_inicio} A.M` }} - {{
                          `${horario.hora_fin} P.M` }}</label>
                      </v-col>
                      <v-col v-else class="d-flex">
                        <label for="" class="d-flex justify-start mr-4"><strong>{{ dia.nombre }}</strong></label>
                        <label class="red--text" for="">Cerrado</label>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </template>
            </modal>
          </v-col>
          <v-col cols="12" sm="4">
            <modal>
              <template #titulo>
                <strong :class="`text-${typeClass}`" class="text-capitalize d-flex"><v-icon
                    class="mr-2"  :class="`text-${typeClass}`">mdi-currency-usd</v-icon>Precios</strong>
              </template>
              <template #ifonModal>
                <v-card-title class="text-h5 d-flex justify-center">
                  <v-icon color="#30A4F1" large>mdi-currency-usd</v-icon><app-title heading="h4" text="Precios"
                    weight="black" textColor="#30A4F1" />
                </v-card-title>

                <v-card-text class="px-16">
                  <div class="d-flex justify-center text-h5 grey--text mt-5 flex-column">
                    <label :class="`text-${typeClass}`" class="mb-3" for="">Nacionales <strong class="font-weight-black">$
                        {{ detalleLuagar.precio_nacional }}</strong></label>
                    <label :class="`text-${typeClass}`" class="mb-3" for="">Extranjeros <strong>$ {{
                      detalleLuagar.precio_extranjero }}</strong></label>
                    <div class="mb-3" v-if="detalleLuagar.gratis_adulto_mayor">
                      <label :class="`text-${typeClass}`" for="">Adultos mayores entran gratis</label>
                    </div>
                    <label :class="`text-${typeClass}`" v-if="detalleLuagar.gratis_ninio_menor" for="">Niños menores de
                      <strong>{{ detalleLuagar.edad_ninio_menor }} años</strong>
                      entran gratis</label>
                  </div>
                </v-card-text>
              </template>
            </modal>
          </v-col>
          <v-col cols="12" sm="4">
            <modal>
              <template #titulo>
                <strong :class="`text-${typeClass}`" class="text-capitalize  d-flex"><v-icon
                    class="mr-2"  :class="`text-${typeClass}`">mdi-book-open-outline</v-icon>Contactos</strong>
              </template>
              <template #ifonModal>
                <v-card-title class="text-h5 d-flex justify-center">
                  <v-icon class="mr-2" color="#30A4F1" large>mdi-book-open-outline</v-icon><app-title heading="h4"
                    text="Contactos" weight="black" textColor="#30A4F1" />
                </v-card-title>
                <div v-for="(contacto, index) in contactos" :key="index">
                  <div v-for="(contact, index) in contacto" class="d-flex justify-center" :key="index">
                    <div class="d-flex flex-column text-h5 grey--text mt-3">
                      <label :class="`text-${typeClass}`" for="">
                        {{ contact.current?.contacto }}
                      </label>
                    </div>
                    <div class="d-flex flex-row d-flex justify-center text-h5 grey--text mt-3 mb-7">
                      <div v-for="(red, index) in contact.redes" :key="index">
                        <a :href="red.contacto" class="mx-3" target="_blank"
                           rel="noreferrer">
                          <v-icon x-large>{{ red.tipo_contacto.icono }}</v-icon>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </modal>
          </v-col>
        </v-row>
       </v-container>
        <v-container class="mt-6">
          <v-row>
            <v-col class="d-flex justify-center">
              <div class="d-flex flex-column">
                <app-title heading="h3" :text="detalleLuagar.nombre" weight="bold" textColor="#30A4F1"
                  flex="justify-center" />
                <div class="d-flex justify-center">
                  <label class="font-weight-medium" for=""> {{ detalleLuagar.municipio?.nombre }}, {{
                    detalleLuagar.municipio?.departamento?.nombre }} </label>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
       <v-container>
         <div cols="12" class="justify-center mt-10 mr-5 ml-5">
          <div v-html="detalleLuagar.descripcion" class="justify-center text-justify text-lg-h6 grey--text"></div>
        </div>
       </v-container>
          <Slider :carrouselisto="dataReady" :total-items="!!detalleLuagar?.uuid_actividad ? detalleLuagar.uuid_actividad.length : 1">
            <template #componentCard>
              <div class="slide" v-for="(url_img, index) in detalleLuagar.uuid_actividad" :key="index">
                <cardDetailPlace :urlImg="`${path}${detalleLuagar.path_file}/${url_img}`"></cardDetailPlace>
              </div>
            </template>
          </Slider>
        <v-container>
          <v-row>
            <v-col cols="12" class="d-flex justify-center">
              <div class="d-flex flex-column">
                <app-title heading="h4" text="En este lugar tu podrás realizar" weight="bold" textColor="#30A4F1" />
                <app-title heading="h4" text=" diversas actividades" weight="black" textColor="#30A4F1"
                  flex="justify-center" />
              </div>
            </v-col>
            <v-col cols="12" sm="4" v-for="({ nombre }, index) in detalleLuagar.actividades" :key="index"
              class="d-flex justify-center mt-6">
              <label class="grey--text text-lg-h6" for=""><v-icon color="#30A4F1">mdi-check-decagram-outline</v-icon>
                {{ nombre }}</label>
            </v-col>
          </v-row>
        </v-container>
        <v-row class="gastronomia--color mt-16 mx-0">
          <v-col class="d-flex justify-center mt-5">
            <div class="d-flex flex-column">
              <app-title heading="h3" text="Gastronomía que" weight="bold" textColor="#30A4F1" flex="justify-center" />
              <app-title heading="h3" text="encontrarás en el lugar" weight="bold" textColor="#30A4F1" />
            </div>
          </v-col>
          <v-col cols="12">
          <v-container>
            <div cols="12" class="justify-center mt-10 mr-5 ml-5">
             <div v-html="detalleLuagar.gastronomia" class="justify-center text-justify text-lg-h6 grey--text"></div>
            </div>
          </v-container>
            <Slider :carrouselisto="dataReady" :total-items="!!detalleLuagar?.uuid_gastronomia ? detalleLuagar.uuid_gastronomia.length : 1">
              <template #componentCard>
                <div class="slide" v-for="(url_img, index) in detalleLuagar.uuid_gastronomia" :key="index">
                  <cardDetailPlace :urlImg="`${path}${detalleLuagar.path_file}/${url_img}`"></cardDetailPlace>
                </div>
              </template>
            </Slider>
          </v-col>
        </v-row>
        <v-container class="mt-10">
          <v-row>
            <v-col class="d-flex justify-center">
              <app-title heading="h3" text="Ubicación del sitio" weight="bold" textColor="#30A4F1" />
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <app-mapa :waypoints="latlog" :readyMap="dataReady" />
            </v-col>
          </v-row>
        </v-container>
      </template>
    </app-layout-component>
  </section>
</template>
<script>
import ComponentCardCategoria from "./components/ComponentCardEvento.vue";
import Slider from './components/slider.vue';
import Modal from './components/modal.vue';
import cardDetailPlace from "./components/cardDetailPlace.vue";
export default {
  data: () => ({
    model: null,
    path: `${process.env.VUE_APP_API_URL}/api/v1/recreacion/imagen?path=`,
    detalleLuagar: {},
    dataReady: false,
    contactos: [],
    img_portada: null
  }),
  async created() {
    const reponse = await this.services.Recreacion.getLugar(this.$route.params.id);
    this.detalleLuagar = reponse.data
    this.contactos = this.detalleLuagar.contactos.reduce((acc, current, index) => {
      const cont = acc
      if (current.tipo_contacto.id < 3) cont[0].push({ current })
      else cont[1][0].redes.push(current)
      return cont
    }, [[], [{ redes: [] }]])
    this.dataReady = true;
    this.img_portada = `${process.env.VUE_APP_API_URL}/api/v1/recreacion/imagen?path=${this.detalleLuagar.path_file}${this.detalleLuagar.uuid_portada[0]}`
  },
  components: {
    Slider,
    ComponentCardCategoria,
    Modal,
    cardDetailPlace
  },
  computed: {
    typeClass() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'body-2'
        case 'sm': return 'body-1'
        case 'md': return 'h5'
        case 'lg': return 'h6'
        case 'xl': return 'h4'
      }
    },
    latlog() {
      return [Number(this.detalleLuagar.latitud), Number(this.detalleLuagar.longitud)]
    },
  textHeader() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'h5'
        case 'sm': return 'h4'
        case 'md': return 'h3'
        case 'lg': return 'h1'
        case 'xl': return 'h1'
      }
    }
  },
};
</script>
<style>
.group-button {
  margin-top: -2.2rem;
}

.gastronomia--color {
  background-color: #cccddf33;
}

.text--horario {
  width: 21%;
}

@media screen and (max-width: 959px) {
  .group-button{
    margin-top: 2.5rem;
}
}
</style>
